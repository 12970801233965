const Domain = {
    Beta: 'beta',
    Prod: 'prod'
}

export const getApiGatewayEndpoint = () => {
    return 'https://pl71mtwcug.execute-api.us-west-2.amazonaws.com/prod';
}

export const shouldReportUserSessions = () => {
    return process.env.REACT_APP_DOMAIN === Domain.Prod;
}
