import apiClientFactory from 'aws-api-gateway-client';
import {getApiGatewayEndpoint} from "../env/env";

class HttpClient {

    #client = apiClientFactory.newClient({
        invokeUrl: getApiGatewayEndpoint(),
        region: 'us-west-2',
        accessKey: 'AKIA2FLFFPTUSIXW6CHX',
        secretKey: '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',
    });

    subscribeToEmailList = async (email) => {
        await this.#client.invokeApi({}, '/email-list/subscribe', 'POST', {}, {
            email,
        });
    }

    stall = async (stallTime = 2000) => {
        await new Promise(resolve => setTimeout(resolve, stallTime));
    }
}

export default HttpClient;
